import { CheckoutOverview } from "../../components/CheckoutOverview/CheckoutOverview";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { useStep } from "../../hooks/useStep";
import { MainLayout } from "../../layouts/MainLayout/MainLayout";

export const OverviewPage = () => {
  const { t } = useFeTranslation();
  const { goBack } = useStep();

  return (
    <MainLayout onBackButtonClick={goBack}>
      <PageTitle
        title={t("pages.overview.title")}
        helpText={t("pages.overview.helpText")}
      />
      <CheckoutOverview />
    </MainLayout>
  );
};

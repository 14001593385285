import { createStore, useStore } from "zustand";
import { PersistStorage, persist } from "zustand/middleware";

export enum ConsentCategory {
  Required = "required",
  Analytics = "analytics",
}

export type ConsentStore = {
  acceptedCategories: ConsentCategory[];
  setAcceptedCategories: (acceptedCategories: ConsentCategory[]) => void;
};

const storage: PersistStorage<ConsentStore> = {
  getItem: (name) => {
    if (Boolean(localStorage)) {
      const item = localStorage.getItem(name);
      if (!item) return null;
      return JSON.parse(item);
    }
    return null;
  },
  setItem: (name, value) => {
    if (Boolean(localStorage)) {
      localStorage.setItem(name, JSON.stringify(value));
    }
  },
  removeItem: (name) => {
    if (Boolean(localStorage)) {
      localStorage.removeItem(name);
    }
  },
};

export const consentStore = createStore<ConsentStore>()(
  persist(
    (set, get) => ({
      acceptedCategories: [],
      setAcceptedCategories: (acceptedCategories) => set({ acceptedCategories }),
    }),
    {
      name: "consentStore",
      version: 1,
      storage,
      partialize: (state) =>
        ({
          acceptedCategories: state.acceptedCategories,
        } as ConsentStore),
    }
  )
);

export const useConsentStore = () => useStore(consentStore);

import { Divider, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ReactNode } from "react";
import HelpIcon from "../../icons/HelpIcon.svg";

type Props = {
  title: string;
  help?: string;
  children?: ReactNode;
  helpText?: string;
};

export const PageTitle = ({ title, help, children, helpText }: Props) => {
  return (
    <Stack>
      <Stack direction="row">
        <Stack
          sx={{
            width: "calc(100% - 24px)",
          }}
        >
          <Typography fontWeight={700}>{title}</Typography>
          {children}
        </Stack>
        {helpText && (
          <Tooltip title={helpText} arrow disableFocusListener>
            <img
              src={HelpIcon}
              width={24}
              height={24}
              title={help}
              style={{
                marginTop: "auto",
              }}
            />
          </Tooltip>
        )}
      </Stack>
      <Divider
        sx={{
          margin: "2px 0",
        }}
      />
    </Stack>
  );
};

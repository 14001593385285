import { Stack } from "@mui/system";
import { TicketList } from "./TicketList/TicketList";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { Button, Divider, Typography } from "@mui/material";
import { Ticket } from "./TicketSelector.types";
import KeyboardArrowRightIcon from "../../icons/KeyboardArrowRightIcon.svg";
import { formatCurrency } from "../../util/i18n.util";
import testId from "../../../../cypress/support/testId";
import { BottomBar } from "../BottomBar/BottomBar";

type Props = {
  tickets: Ticket[];
  extras: Ticket[];
  currency: string;
  onChange: (id: string, value: number, isExtra: boolean) => void;
  onNext: () => void;
};

export const TicketSelector = ({
  tickets,
  extras,
  currency,
  onChange,
  onNext,
}: Props) => {
  const { t } = useFeTranslation();

  return (
    <Stack
      sx={{
        marginBottom: {
          md: "0",
          xs: "120px", // height of the bottom bar with the total price
        },
      }}
    >
      <TicketList
        categoryName={t("pages.tickets.ticketType")}
        tickets={tickets}
        currency={currency}
        onChange={(id, value) => onChange(id, value, false)}
      />
      {extras.length > 0 && (
        <>
          <Divider
            sx={{
              marginTop: "16px",
            }}
          />
          <TicketList
            categoryName={t("pages.tickets.extras")}
            tickets={extras}
            currency={currency}
            onChange={(id, value) => onChange(id, value, true)}
          />
        </>
      )}

      <Divider
        sx={{
          display: {
            md: "block",
            xs: "none",
          },
          marginTop: "10px",
        }}
      />
      <BottomBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          margin={{
            md: "15px 0",
            xs: "15px 15px 10px 15px",
          }}
        >
          <Typography fontWeight={700} fontSize="24px">
            {t("pages.tickets.total")}
          </Typography>
          <Typography fontWeight={700} fontSize="24px">
            {formatCurrency(
              [...tickets, ...extras].reduce(
                (acc, ticket) => acc + ticket.price * ticket.selectedQuantity,
                0
              ),
              currency
            )}
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<img src={KeyboardArrowRightIcon} />}
          sx={{
            width: {
              md: "300px",
            },
            margin: {
              md: "0 0 16px auto",
              xs: "0 15px 15px 15px",
            },
          }}
          onClick={onNext}
          disabled={tickets.every((ticket) => ticket.selectedQuantity === 0)}
          data-cy={testId.tickets.next}
        >
          {t("common.next")}
        </Button>
      </BottomBar>
    </Stack>
  );
};

import { Stack } from "@mui/system";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { Chip } from "@mui/material";

export const CalendarLabels = () => {
  const { t } = useFeTranslation();

  return (
    <Stack marginBottom={{ md: "16px", xs: "16px" }} marginTop="4px">
      <Stack gap={1.25} direction="row" flexWrap="wrap" marginBottom={0.5}>
        <Chip
          label={t("pages.date.available")}
          size="small"
          variant="filled"
          color="primary"
        />
        <Chip
          label={t("common.fewTicketsLeft")}
          size="small"
          variant="filled"
          color="warning"
        />
      </Stack>
      <Stack gap={1.25} direction="row" flexWrap="wrap">
        <Chip
          label={t("pages.date.noTicketsLeft")}
          size="small"
          variant="filled"
          color="default"
        />
        <Chip
          label={t("pages.date.today")}
          size="small"
          variant="outlined"
          color="secondary"
        />
      </Stack>
    </Stack>
  );
};

import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import { useUserStore } from "../../store/user.store";
import { useTenantStore } from "../../store/tenant.store";
import { useEffect, useMemo } from "react";
import { Timeslot } from "../../components/ServiceSelector/ServiceSelector.types";
import { TimeslotSelector } from "../../components/TimeslotSelector/TimeslotSelector";
import { useStep } from "../../hooks/useStep";
import { getTimeslotsWithTicketsForDateByService } from "../../util/service.util";
import { SelectedChoices } from "../../components/SelectedChoiceDisplay";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";
import { useTenant } from "../../hooks/useTenant";

export const TimeslotPage = () => {
  const {
    selectedDate,
    selectedEvent,
    selectedTickets,
    setSelectedTimeslot,
    clearSelectedTickets,
    month,
    locale,
    bookingFlowType,
  } = useUserStore();
  const { availableTimeslots, fetchServices, fetchAvailableTimeslots } = useTenantStore();
  const { goForward, goBack } = useStep();
  const tenant = useTenant();

  useEffect(() => {
    if (bookingFlowType === BookingFlowType.VoucherRedeem) return;

    if (
      Object.keys({ ...selectedTickets.tickets, ...selectedTickets.extras }).length > 0
    ) {
      clearSelectedTickets();
    }
  }, [selectedTickets, clearSelectedTickets, bookingFlowType]);

  useEffect(() => {
    if (availableTimeslots) return;

    if (selectedEvent?.id) {
      fetchAvailableTimeslots(tenant, month, selectedEvent.id);
    }
  }, [availableTimeslots, selectedEvent, month]);

  const timeslots = useMemo(
    () => getTimeslotsWithTicketsForDateByService(availableTimeslots, selectedDate),
    [availableTimeslots, selectedDate, getTimeslotsWithTicketsForDateByService]
  );

  const handleTimeslotSelect = (timeslot: Timeslot) => {
    setSelectedTimeslot({ id: timeslot.id, start: timeslot.start, end: timeslot.end });
    goForward();
  };

  return (
    <MainLayout onBackButtonClick={goBack}>
      <SelectedChoices />
      <TimeslotSelector
        timeslots={timeslots[selectedEvent?.id]}
        onSelect={handleTimeslotSelect}
      />
    </MainLayout>
  );
};

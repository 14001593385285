import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { formatCurrency } from "../../util/i18n.util";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { useTenantStore } from "../../store/tenant.store";
import { Trans } from "react-i18next";

type Props = {
  children: number;
  currency?: string;
  withUnit?: boolean;
  withFrom?: boolean;
};

export const Price = ({
  children: price,
  currency,
  withUnit = true,
  withFrom,
}: Props) => {
  const { t } = useFeTranslation();
  const { tenantDetails } = useTenantStore();

  return (
    <Stack direction="row" alignItems="flex-end">
      <Trans
        t={t}
        i18nKey="common.pricePerPerson"
        values={{
          price: formatCurrency(price, currency ?? tenantDetails?.currency),
        }}
        components={{
          price: (
            <Typography
              className="price"
              fontWeight="700"
              fontSize={{
                md: "24px",
                xs: "16px",
              }}
              sx={{
                marginRight: withUnit ? "4px" : "0",
              }}
            />
          ),
          unit: (
            <Typography
              fontSize="12px"
              lineHeight={{
                md: "2.25",
                xs: "1.75",
              }}
              sx={{
                display: withUnit && !withFrom ? "block" : "none",
              }}
            />
          ),
          unitfrom: (
            <Typography
              fontSize="12px"
              lineHeight={{
                md: "2.25",
                xs: "1.75",
              }}
              sx={{
                display: withFrom ? "block" : "none",
                "&:has(+ p)": {
                  marginRight: "4px",
                },
              }}
            />
          ),
        }}
      ></Trans>
    </Stack>
  );
};

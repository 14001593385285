import { Box, useTheme } from "@mui/system";
import { defaultTheme } from "../../config/theme";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { Step, useStep } from "../../hooks/useStep";
import { useMemo } from "react";

export const StepIndicator = () => {
  const { t } = useFeTranslation();
  const theme = useTheme(defaultTheme);
  const { currentStep, getNonSkippableSteps } = useStep();
  const nonSkippableSteps = new Set(getNonSkippableSteps());

  const steps: [Step, string][] = [
    [Step.Date, t("stepIndicator.date")],
    [Step.Service, t("stepIndicator.service")],
    [Step.Timeslot, t("stepIndicator.timeslot")],
    [Step.Tickets, t("stepIndicator.tickets")],
    [Step.Overview, t("stepIndicator.overview")],
  ];
  const filteredSteps = useMemo(
    () => steps.filter((s) => nonSkippableSteps.has(s[0] as Step)),
    [nonSkippableSteps]
  );
  const stepIndices = useMemo(
    () => filteredSteps.reduce((acc, s, i) => ({ [s[0]]: i, ...acc }), {}),
    [filteredSteps]
  );

  const getStepColor = (step: Step) => {
    if (stepIndices[step] < stepIndices[currentStep]) {
      return theme.palette.primary.main;
    } else if (stepIndices[step] === stepIndices[currentStep]) {
      return theme.palette.secondary.main;
    } else {
      return theme.palette.grey[500];
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${Object.keys(filteredSteps).length}, ${
          100 / Object.keys(filteredSteps).length
        }%)`,
        margin: {
          md: "4px 0 16px 0",
          xs: "30px 0 8px 0",
        },
      }}
    >
      {filteredSteps.map((step, i) => (
        <Box
          sx={{
            fontSize: "14px",
            display: "flex",
            flexDirection: "column",
            margin: "0 2px",
            overflowWrap: "break-word",
            cursor: "default",
            color: getStepColor(step[0]),
          }}
          key={i}
        >
          <Box
            sx={{
              width: "100%",
              height: i > stepIndices[currentStep] ? "1px" : "4px",
              marginBottom: i > stepIndices[currentStep] ? "7px" : "4px",
              backgroundColor: getStepColor(step[0]),
            }}
          />
          <span>{step[1]}</span>
        </Box>
      ))}
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import * as Sentry from "@sentry/react";
import { ConsentCategory, useConsentStore } from "../../store/consent.store";
import { Button, Dialog, Link, Stack, Typography } from "@mui/material";
import { Modal } from "@mui/base";

const initConsent = ({ acceptedCategories }) => {
  if (acceptedCategories.includes(ConsentCategory.Analytics)) {
    Sentry.init({
      dsn: "https://a4d111a0606be3241d96eae07c424fe0@o4506517922447360.ingest.sentry.io/4506517925068800",
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["localhost", /^https:\/\/flokk\.io\/api/],
        }),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    console.log("sentry loaded");
  }
};

export default function CookieBanner() {
  const { acceptedCategories, setAcceptedCategories } = useConsentStore();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!open && acceptedCategories.length === 0) {
      handleOpen();
    }
    initConsent({ acceptedCategories });
  }, [acceptedCategories.length]);

  if (acceptedCategories.length > 0) {
    return null;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            position: "absolute",
            bottom: 0,
            left: 0,
            margin: 0,
            width: "100vw",
            maxWidth: "100vw",
          },
        }}
      >
        <Stack
          sx={(theme) => ({
            bgcolor: "background.main",
            boxShadow: 24,
            p: 4,
            backgroundColor: theme.palette.background.default,
            outline: 0,
          })}
          gap={2}
          direction={{
            md: "row",
            xs: "column",
          }}
          justifyContent="space-between"
        >
          <Stack marginBottom={1} sx={{ fontSize: "14px" }}>
            <Typography variant="body2" lineHeight={1.5} fontSize="14px">
              We use some analytics cookies to ensure that everything runs smoothly during
              booking. We don't use cookies for marketing, only to give you a great
              experience. By accepting all cookies, you help us keep Flokk in good shape.
              Thanks!
            </Typography>
            <Typography marginTop={2} variant="body2" fontSize="14px">
              See our{" "}
              <Link href="https://cdn.flokk.io/assets/privacy-policy.pdf" target="_blank">
                privacy policy
              </Link>{" "}
              for more details.
            </Typography>
          </Stack>
          <Stack
            direction="column"
            gap={1.5}
            minWidth={{
              md: "320px",
              xs: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setAcceptedCategories([ConsentCategory.Required])}
            >
              Necessary cookies only
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                setAcceptedCategories([
                  ConsentCategory.Required,
                  ConsentCategory.Analytics,
                ])
              }
            >
              Accept all cookies
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  SxProps,
} from "@mui/material";
import { ReactNode, useState } from "react";
import KeyboardArrowDownIcon from "../../icons/KeyboardArrowDownIcon.svg";
import PencilIcon from "../../icons/PencilIcon.svg";

type Props = {
  title: string;
  sx?: SxProps;
  onEditClick: () => void;
  children: ReactNode;
};

export const CollapsibleCheckoutOverviewSection = ({
  title,
  sx,
  onEditClick,
  children,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      sx={{ position: "relative", ...sx }}
    >
      <AccordionSummary expandIcon={<img src={KeyboardArrowDownIcon} />}>
        {title}
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onEditClick();
          }}
          component="img"
          src={PencilIcon}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

import React from "react";
import { useParams } from "wouter";
import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import { useTenantStore } from "../../store/tenant.store";
import { ServiceDetails } from "../../components/ServiceDetails/ServiceDetails";
import { useEffect } from "react";
import { useUserStore } from "../../store/user.store";
import { Step, useStep } from "../../hooks/useStep";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";
import { useSDK } from "../../hooks/useSDK";
import { FallbackLocaleInputType, LocaleInputType } from "../../graphql/graphql";

export function ServiceDetailsPage() {
  const { serviceid } = useParams();
  const { goToStep, search } = useStep();
  const { setSelectedEvent } = useUserStore();
  const { services } = useTenantStore();
  const isPreview = search.get("preview") === "true";

  const service = !services ? null : services.filter((s) => s.id === serviceid)[0];

  return (
    <MainLayout
      stepIndicatorVisible={false}
      onBackButtonClick={
        isPreview
          ? undefined
          : () => {
              goToStep(Step.Service);
            }
      }
    >
      <ServiceDetails
        service={service}
        onSelect={
          isPreview
            ? null
            : () => {
                setSelectedEvent({ id: service.id, name: service.title });
                goToStep(Step.Timeslot);
              }
        }
      />
    </MainLayout>
  );
}

export function ServicePreselectPage() {
  const { serviceid } = useParams();
  const { goToStep } = useStep();
  const { fetchServicesByTenant, services } = useTenantStore();
  const { locale, setSelectedEvent, setBookingFlowType } = useUserStore();
  const service = (services || []).filter((s) => s.id === serviceid)[0];

  return (
    <MainLayout stepIndicatorVisible={false}>
      <ServiceDetails
        service={service}
        onSelect={() => {
          setBookingFlowType(BookingFlowType.ServicePreselect);
          setSelectedEvent({ id: service.id, name: service.title });
          goToStep(Step.Date);
        }}
      />
    </MainLayout>
  );
}

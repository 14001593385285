import { Stack } from "@mui/system";
import { useUserStore } from "../../store/user.store";
import { PageTitle } from "../PageTitle/PageTitle";
import { Typography } from "@mui/material";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";

export const SelectedChoices = ({ helpText }: { helpText?: string }) => {
  const { t } = useFeTranslation();
  const { selectedDate, selectedEvent, bookingFlowType } = useUserStore();

  const shouldShowDetails = bookingFlowType !== BookingFlowType.VoucherPurchase;

  return (
    <PageTitle title={t("pages.event.title")} helpText={helpText}>
      {shouldShowDetails && (
        <>
          {selectedDate && (
            <Stack direction="row" alignItems="center" gap="4px">
              {t("pages.event.date")}:
              <Typography fontWeight="700">
                {selectedDate?.toLocaleDateString()}
              </Typography>
            </Stack>
          )}
          {selectedEvent?.id && (
            <Stack direction="row" alignItems="center" gap="4px">
              {t("pages.event.event")}:
              <Typography fontWeight="700" noWrap>
                {selectedEvent.name}
              </Typography>
            </Stack>
          )}
        </>
      )}
    </PageTitle>
  );
};

import { Stack } from "@mui/system";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const BottomBar = ({ children }: Props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        boxShadow: {
          md: "none",
          xs: "0px -4px 6px 0px rgba(0, 0, 0, 0.15)",
        },
        position: {
          md: "static",
          xs: "fixed",
        },
        bottom: "0",
        left: "0",
        backgroundColor: "#fff",
      }}
    >
      {children}
    </Stack>
  );
};

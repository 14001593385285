import hu from "./hu/translation.json";
import payloadHu from "./hu/payloadTranslation.json";
import en from "./en/translation.json";
import payloadEn from "./en/payloadTranslation.json";

export type Translation = typeof hu;

// this way we get a type error if keys don't match in the translation files
const locales: Record<
  string,
  { translation: Translation; general: Record<string, string> }
> = {
  hu: {
    translation: hu,
    ...payloadHu,
  },
  en: {
    translation: en,
    ...payloadEn,
  },
};

export default locales;

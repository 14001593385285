import React from "react";
import Helmet from "react-helmet";
import { ConsentCategory, useConsentStore } from "../../store/consent.store";
import { useTenantStore } from "../../store/tenant.store";

export default function GoogleAnalytics() {
  const { acceptedCategories } = useConsentStore();
  const { tenantDetails } = useTenantStore();

  if (
    !acceptedCategories.includes(ConsentCategory.Analytics) ||
    !tenantDetails?.googleAnalyticsMeasurementID
  ) {
    return;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${tenantDetails?.googleAnalyticsMeasurementID}`}
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${tenantDetails.googleAnalyticsMeasurementID}');`}
      </script>
    </Helmet>
  );
}

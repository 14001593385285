import { Box } from "@mui/system";
import { useEffect, useState } from "react";

type Props = {
  id: string;
  items?: any;
  threshold?: number;
};

export const Fade = ({ id, items, threshold = 0.5 }: Props) => {
  const [bottomReached, setBottomReached] = useState(false);

  useEffect(() => {
    let target = document.getElementById(id);
    if (!target) return;

    let observer = new IntersectionObserver(
      (entries) => {
        setBottomReached(entries[0].isIntersecting);
      },
      { threshold: [threshold] }
    );
    observer.observe(target);

    return () => observer.unobserve(target);
  }, [id, items]);

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      height="60px"
      sx={{
        background: "linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 40px)",
        display: {
          md: "none",
          xs: "block",
        },
        opacity: bottomReached ? 0 : 1,
        transition: "opacity 0.2s ease-in-out",
        pointerEvents: "none",
      }}
    />
  );
};

import { useJitsu } from "@jitsu/jitsu-react";
import { Box, Stack, useTheme } from "@mui/system";
import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import LandscapeIllustration from "../../icons/LandscapeIllustration.svg";
import CheckCircleIcon from "../../icons/CheckCircleIcon.svg";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { Link, Typography } from "@mui/material";
import { useTenantStore } from "../../store/tenant.store";
import { useStep } from "../../hooks/useStep";
import { useEffect, useState } from "react";
import { sdk } from "../../hooks/useSDK";
import { Trans } from "react-i18next";
import { useUserStore } from "../../store/user.store";
import { defaultTheme } from "../../config/theme";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";
import { useTenant } from "../../hooks/useTenant";

type PaymentSuccessFlowType = Exclude<BookingFlowType, BookingFlowType.ServicePreselect>;

export const PaymentSuccess = () => {
  const { t } = useFeTranslation();
  const { search } = useStep();
  const theme = useTheme(defaultTheme);
  const tenant = useTenant();

  const { tenantDetails, fetchTenantDetails } = useTenantStore();
  const { reset, setVoucherCode } = useUserStore();

  const [bookingDetails, setBookingDetails] = useState({
    email: "",
    bookingNumber: "",
    flowType: BookingFlowType.FullBooking as PaymentSuccessFlowType,
  });

  useEffect(() => {
    const stripeId = search.get("session_id");
    if (!stripeId) return;

    (async () => {
      const result = await sdk.checkoutStatus({
        id: stripeId,
        tenant,
      });

      fetchTenantDetails(result.checkoutStatus.tenantId);

      setBookingDetails({
        email: result.checkoutStatus.email,
        bookingNumber: result.checkoutStatus.bookingNumber,
        flowType: result.checkoutStatus.bookingFlowType as PaymentSuccessFlowType,
      });

      // reset persisted state after successful payment
      reset();
      setVoucherCode(null);
    })();
  }, [search]);

  return (
    <MainLayout
      stepIndicatorVisible={false}
      tenantInfoPosition="none"
      sx={{
        [theme.breakpoints.down("md")]: {
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",

          "> :last-child": { flex: 1, "> *": { display: "flex" } },
        },
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        textAlign="center"
        sx={{
          flex: 1,
          maxWidth: "100%",

          "> *": {
            maxWidth: "600px",
          },
        }}
      >
        <Box>
          <Box
            component="img"
            src={CheckCircleIcon}
            width="40px"
            marginTop={{ md: "20px" }}
          />
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              margin: "10px 0 40px 0",
            }}
          >
            {t(`pages.paymentSuccess.${bookingDetails.flowType}.title1`)}
            <br />
            {t(`pages.paymentSuccess.${bookingDetails.flowType}.title2`)}
          </Typography>
        </Box>

        <Box>
          <Typography>
            {t(`pages.paymentSuccess.${bookingDetails.flowType}.details`)}:
          </Typography>
          <Typography fontWeight={700}>{bookingDetails.email}.</Typography>
          <Typography marginTop="20px">
            {t("pages.paymentSuccess.bookingNumber")}:
          </Typography>
          <Typography fontWeight={700}>{bookingDetails.bookingNumber}</Typography>
          <Typography
            fontWeight={700}
            sx={{
              ":not(:empty)": {
                marginTop: "16px",
              },
            }}
          >
            {t(`pages.paymentSuccess.${bookingDetails.flowType}.reminder`)}
          </Typography>
          <Typography
            fontSize={{ md: "16px", xs: "14px" }}
            marginTop={{
              md: "40px",
              xs: "20px",
            }}
          >
            <Trans
              t={t}
              i18nKey="pages.paymentSuccess.questions"
              values={{
                email: tenantDetails?.contactEmail,
                website: tenantDetails?.websiteURL,
              }}
              components={{
                email: <Link href={`mailto:${tenantDetails?.contactEmail}`} />,
                website: <Link href={tenantDetails?.websiteURL} />,
              }}
            />
            .
          </Typography>
        </Box>

        <Box
          component="img"
          src={LandscapeIllustration}
          width={{ md: "500px" }}
          margin="20px 0"
        />
      </Stack>
    </MainLayout>
  );
};

import { Stack } from "@mui/system";
import { useFeTranslation } from "../../../hooks/useFeTranslation";
import { Trans } from "react-i18next";
import { Typography } from "@mui/material";
import { format } from "date-fns";

type Props = {
  time: Date;
  variant: "from" | "to";
};

export const TimeslotFromTo = ({ time, variant }: Props) => {
  const { t } = useFeTranslation();

  return (
    <Stack direction="row">
      <Trans
        t={t}
        i18nKey={`common.${variant}`}
        values={{ h: format(time, "HH"), m: format(time, "mm") }}
        components={{
          // hours
          h: <Typography fontWeight="700" fontSize="24px" lineHeight="20px" />,
          // minutes
          m: <sup style={{ fontWeight: 700 }} />,
          // affix (from/to)
          a: (
            <Typography
              fontSize="12px"
              margin="4px 0 -4px 0"
              lineHeight="20px"
              sx={{
                "& + p": {
                  marginLeft: "4px",
                },
              }}
            />
          ),
        }}
      />
    </Stack>
  );
};

import { Box, Stack } from "@mui/system";
import { BookingFlowButton } from "./BookingFlowButton";
import CalendarIcon from "../../icons/CalendarIcon.svg";
import TicketIcon from "../../icons/TicketIcon.svg";
import EyeIcon from "../../icons/EyeIcon.svg";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { useStep } from "../../hooks/useStep";
import { useUserStore } from "../../store/user.store";
import { useTenantStore } from "../../store/tenant.store";
import { useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";

type Props = {};

export const BookingFlowSelector = ({}: Props) => {
  const { t } = useFeTranslation();
  const { goForward, goTo, search } = useStep();
  const { setBookingFlowType, bookingFlowType, selectedEvent, setVoucherCode } =
    useUserStore();
  const { services } = useTenantStore();

  const handleVoucherFlowButtonClick = () => {
    setBookingFlowType(BookingFlowType.VoucherPurchase);
    goForward();
    // should we hide the calendar in this state?
    // if the user goes back to the date page, they will be able to select a date
  };

  const handleFullBookingFlowButtonClick = () => {
    setBookingFlowType(BookingFlowType.FullBooking);
  };

  const voucherService = useMemo(
    () => services?.find((s) => s.id === selectedEvent?.id),
    [services, bookingFlowType, selectedEvent]
  );

  const handleServiceDetailsClick = () => {
    if (!voucherService?.id) return;
    goTo(`/service/${voucherService.id}`);
  };

  useEffect(() => {
    const voucher = search.get("voucher");
    if (voucher?.length > 0) {
      setVoucherCode(voucher);

      if (bookingFlowType !== BookingFlowType.VoucherRedeem) {
        setBookingFlowType(BookingFlowType.VoucherRedeem);
      }
    }
  }, [search, bookingFlowType]);

  if (bookingFlowType === BookingFlowType.VoucherRedeem) {
    return (
      <Stack marginBottom="24px">
        <Typography fontWeight={700}>{t("pages.date.usingVoucher")}</Typography>
        <Stack direction="row" gap="5px">
          <Typography>{t("pages.date.event")}:</Typography>
          <Typography fontWeight={700}>{voucherService?.title}</Typography>
          <Box
            component="img"
            src={EyeIcon}
            sx={{ cursor: "pointer" }}
            onClick={handleServiceDetailsClick}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      gap="14px"
      margin="20px 0"
      sx={{
        "> *": {
          width: "50%",
        },
      }}
    >
      <BookingFlowButton
        icon={CalendarIcon}
        onClick={handleFullBookingFlowButtonClick}
        selected={
          bookingFlowType === BookingFlowType.FullBooking ||
          bookingFlowType === BookingFlowType.ServicePreselect
        }
        infoText={t("pages.date.bookWithTimeInfo")}
      >
        {t("pages.date.bookWithTime")}
      </BookingFlowButton>
      <BookingFlowButton
        icon={TicketIcon}
        onClick={handleVoucherFlowButtonClick}
        selected={bookingFlowType === BookingFlowType.VoucherPurchase}
        infoText={t("pages.date.bookWithoutTimeInfo")}
      >
        {t("pages.date.bookWithoutTime")}
      </BookingFlowButton>
    </Stack>
  );
};

import React from "react";

export default function Logo({
  fill = "black",
  width = "32px",
  height = "32px",
}: {
  fill: string;
  width: string;
  height: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 151 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M148.653 30.895C139.532 30.895 131.748 32.8467 124.91 36.1449V2.14093C124.91 0.957573 123.951 0 122.769 0C91.8037 0 76.1523 22.371 61.1553 44.2445C46.197 47.8803 31.7855 51.1356 2.6508 44.0521C2.01103 43.8974 1.33781 44.0438 0.8214 44.4494C0.304986 44.855 0.00390625 45.4759 0.00390625 46.1324V90.1239C0.00390625 91.3073 0.962509 92.2648 2.14484 92.2648C11.2651 92.2648 19.0492 90.3131 25.8874 87.015V121.019C25.8874 122.202 26.846 123.16 28.0284 123.16C58.9935 123.16 74.6469 100.787 89.6429 78.9133C104.601 75.2817 119.013 72.0264 148.147 79.1077C148.786 79.2541 149.459 79.114 149.977 78.7105C150.493 78.3049 150.794 77.6839 150.794 77.0274V33.0359C150.794 31.8526 149.836 30.895 148.653 30.895ZM40.0605 77.3463C49.1933 69.1432 56.5549 58.5264 63.7594 48.0183C76.5952 44.8999 89.0585 42.0816 110.736 45.8136C101.604 54.0166 94.2433 64.6314 87.0389 75.1395C74.2018 78.2599 61.741 81.0803 40.0605 77.3463Z"
        fill={fill}
      />
    </svg>
  );
}

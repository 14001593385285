import React, { useMemo } from "react";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { TicketSelector } from "../../components/TicketSelector/TicketSelector";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import { useTenantStore } from "../../store/tenant.store";
import { useUserStore } from "../../store/user.store";
import { useStep } from "../../hooks/useStep";
import { getTickets, getTicketsByTimeslots } from "../../util/service.util";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";

export const TicketPage = () => {
  const { t } = useFeTranslation();
  const { tenantDetails, availableTimeslots, services } = useTenantStore();
  const {
    selectedTimeslot,
    selectedTickets,
    selectedEvent,
    updateSelectedTickets,
    bookingFlowType,
  } = useUserStore();
  const { goForward, goBack, goToStep } = useStep();
  const hasServices = Boolean(services) && services?.length > 0;

  const { tickets, extras } = useMemo(() => {
    if (!hasServices) {
      return { tickets: [], extras: [] };
    }
    if (
      bookingFlowType === BookingFlowType.VoucherPurchase ||
      bookingFlowType === BookingFlowType.VoucherRedeem
    ) {
      return getTickets(selectedEvent, selectedTickets, services);
    } else {
      return getTicketsByTimeslots(
        availableTimeslots,
        selectedTimeslot,
        selectedEvent,
        selectedTickets,
        services
      );
    }
  }, [
    availableTimeslots,
    selectedTickets,
    selectedTimeslot,
    selectedEvent,
    hasServices,
    bookingFlowType,
    goToStep,
  ]);

  const handleChange = (id: string, value: number, isExtra: boolean) => {
    updateSelectedTickets(id, value, isExtra);
  };

  const handleNext = () => {
    goForward();
  };

  return (
    <MainLayout onBackButtonClick={goBack}>
      <PageTitle title={t("pages.tickets.title")} />
      <TicketSelector
        tickets={tickets}
        extras={extras}
        currency={tenantDetails?.currency}
        onChange={handleChange}
        onNext={handleNext}
      />
    </MainLayout>
  );
};

import { NumberInput as MuiNumberInput } from "@mui/base/Unstable_NumberInput/NumberInput";
import { useTheme } from "@mui/system";
import { ComponentProps } from "react";
import AddIcon from "../../icons/AddIcon.svg";
import RemoveIcon from "../../icons/RemoveIcon.svg";
import { defaultTheme } from "../../config/theme";
import testId from "../../../../cypress/support/testId";

type Props = ComponentProps<typeof MuiNumberInput>;

export const NumberInput = ({ ...rest }: Props) => {
  const theme = useTheme(defaultTheme);

  const decrementDisabled = (rest.value as number) <= 0;
  const incrementDisabled = (rest.value as number) >= rest.max;

  return (
    <MuiNumberInput
      style={{
        display: "flex",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "2px",
        padding: "10px 4px",
      }}
      slotProps={{
        decrementButton: {
          children: <img src={RemoveIcon} data-cy={testId.tickets.decrement} />,
          style: {
            border: "none",
            backgroundColor: "transparent",
            padding: "0",
            height: "24px",
            cursor: "pointer",
            opacity: decrementDisabled ? "0.5" : "1",
            pointerEvents: decrementDisabled ? "none" : "auto",
          },
        },
        input: {
          style: {
            textAlign: "center",
            width: "30px",
            border: "none",
            outline: "none",
            padding: "0",
            margin: "0 5px",
            height: "24px",
            fontSize: "16px",
          },
        },
        incrementButton: {
          children: <img src={AddIcon} data-cy={testId.tickets.increment} />,
          style: {
            order: 1,
            border: "none",
            backgroundColor: "transparent",
            padding: "0",
            height: "24px",
            cursor: "pointer",
            opacity: incrementDisabled ? "0.5" : "1",
            pointerEvents: incrementDisabled ? "none" : "auto",
          },
        },
      }}
      {...rest}
    />
  );
};

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, Stack, useTheme } from "@mui/system";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { CollapsibleCheckoutOverviewSection } from "./CollapsibleCheckoutOverviewSection";
import { Step, useStep } from "../../hooks/useStep";
import { useTenantStore } from "../../store/tenant.store";
import { useUserStore } from "../../store/user.store";
import React, { useMemo, useState } from "react";
import { format } from "date-fns";
import { defaultTheme } from "../../config/theme";
import { formatCurrency } from "../../util/i18n.util";
import { BottomBar } from "../BottomBar/BottomBar";
import InfoIcon from "../../icons/InfoIcon.svg";
import LockIcon from "../../icons/LockIcon.svg";
import KeyboardArrowRightIcon from "../../icons/KeyboardArrowRightIcon.svg";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";
import { useTenant } from "../../hooks/useTenant";
import { sdk } from "../../hooks/useSDK";
import { APPLICATION_FEE_PERCENT } from "../../../service/pricing";
import { StringOrError } from "../../graphql/graphql";

export const CheckoutOverview = () => {
  const { t } = useFeTranslation();
  const theme = useTheme(defaultTheme);
  const { tenantDetails, services } = useTenantStore();
  const {
    selectedDate,
    selectedEvent,
    selectedTimeslot,
    selectedTickets,
    bookingFlowType,
    voucherCode,
  } = useUserStore();

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [error, setError] = useState<{ message?: string; reason?: string }>(null);

  const tenant = useTenant();

  const { goToStep } = useStep();

  const handleEventDetailsEditClick = () => goToStep(Step.Date);

  const handleTicketDetailsEditClick = () => goToStep(Step.Tickets);

  const isRedeemFlow = bookingFlowType === BookingFlowType.VoucherRedeem;
  const isVoucherPurchaseFlow = bookingFlowType === BookingFlowType.VoucherPurchase;

  const eventDetails = useMemo(() => {
    if (!selectedEvent || !selectedTimeslot || !selectedDate) {
      if (!isVoucherPurchaseFlow) {
        goToStep(Step.Date);
        return;
      }
    }

    const service = services.find((service) => service.id === selectedEvent.id);
    if (!service) {
      return null;
    }

    const time = selectedTimeslot?.start
      ? `${format(new Date(selectedTimeslot.start), "H:mm")}-${format(
          new Date(selectedTimeslot.end),
          "H:mm"
        )}`
      : null;

    const date = selectedDate ? format(selectedDate, "dd MMM yyyy") : null;

    return {
      name: service.title,
      date,
      time,
      location: service.location,
    };
  }, [
    selectedDate,
    selectedEvent,
    selectedTimeslot,
    services,
    bookingFlowType,
    isVoucherPurchaseFlow,
  ]);

  const ticketDetails = useMemo(() => {
    if (!selectedTickets.tickets || !selectedTickets.extras) {
      goToStep(Step.Tickets);
      return;
    }

    const currency = tenantDetails?.currency;
    let total = 0;

    const ticketTypes = services.flatMap((service) => service.ticketTypes);
    const tickets = Object.entries(selectedTickets.tickets).map(
      ([ticketId, quantity]) => {
        const ticket = ticketTypes.find(
          (ticketType) => ticketType.ticketType.id === ticketId
        );

        const totalPriceForTicket = ticket?.ticketType.price * quantity;
        total += totalPriceForTicket;

        return {
          name: ticket?.ticketType?.displayName ?? ticket?.ticketType.name,
          price: formatCurrency(ticket?.ticketType.price, currency),
          quantity,
          total: formatCurrency(totalPriceForTicket, currency),
        };
      }
    );

    const ticketExtras = services.flatMap((service) => service.extras);
    const extras = Object.entries(selectedTickets.extras).map(([extraId, quantity]) => {
      const extra = ticketExtras.find((extra) => extra.extra.id === extraId);

      const totalPriceForTicket = extra?.extra.price * quantity;
      total += totalPriceForTicket;

      return {
        name: extra?.extra?.displayName ?? extra?.extra.name,
        price: formatCurrency(extra?.extra.price, currency),
        quantity,
        total: formatCurrency(totalPriceForTicket, currency),
      };
    });

    const fee = formatCurrency((total * APPLICATION_FEE_PERCENT) / 100, currency);
    const totalWithFee = total + (total * APPLICATION_FEE_PERCENT) / 100;

    return {
      tickets,
      extras,
      fee,
      total: formatCurrency(isRedeemFlow ? 0 : totalWithFee, currency),
    };
  }, [selectedTickets, tenantDetails, services, isRedeemFlow]);

  const terms = (
    <FormControlLabel
      control={
        <Checkbox
          checked={termsAccepted}
          onChange={(e) => setTermsAccepted(e.target.checked)}
        />
      }
      label={
        <Typography fontSize="14px">
          {t("pages.overview.acceptTerms")}
          <Link href={t("pages.overview.termsUrl")} target="_blank">
            {t("pages.overview.terms")}
          </Link>
          .
        </Typography>
      }
    />
  );

  const handleGoToPaymentClick = async () => {
    try {
      setError(null);
      setPaymentInProgress(true);

      const commonInput = {
        tenant,
        serviceID: selectedEvent.id,
        tickets: Object.entries(selectedTickets.tickets).map(([ticketId, quantity]) => ({
          id: ticketId,
          count: quantity,
        })),
        extras: Object.entries(selectedTickets.extras).map(([ticketId, quantity]) => ({
          id: ticketId,
          count: quantity,
        })),
      };

      let response: StringOrError;
      if (bookingFlowType === BookingFlowType.VoucherPurchase) {
        const { createVoucherCheckout } = await sdk.createVoucherCheckout({
          input: commonInput,
        });
        response = createVoucherCheckout;
      } else {
        const { createBooking } = await sdk.createBooking({
          input: {
            ...commonInput,
            start: selectedTimeslot.start,
            end: selectedTimeslot.end,
            voucherID: voucherCode,
          },
        });
        response = createBooking;
      }

      if (response.__typename === "StringResponse") {
        window.location.href = response.response;
      } else if (response.__typename === "Error") {
        setError(response);
        throw new Error(response.reason);
      }
    } catch (e) {
      throw e;
    } finally {
      setPaymentInProgress(false);
    }
  };

  return (
    <Stack
      sx={{
        padding: {
          md: "15px 0 10px 0",
        },
      }}
    >
      {!!eventDetails && (
        <CollapsibleCheckoutOverviewSection
          title={t("pages.overview.eventDetails")}
          onEditClick={handleEventDetailsEditClick}
        >
          <Stack color="#444" gap="5px">
            <Typography fontSize="20px">{eventDetails.name}</Typography>
            {isVoucherPurchaseFlow ? (
              <>
                <Typography>{t("pages.overview.withoutTime")}</Typography>
                <Typography>{t("pages.overview.warning")}</Typography>
              </>
            ) : (
              <>
                <Typography>
                  {t("pages.overview.date")}: {eventDetails.date}
                </Typography>
                <Typography>
                  {t("pages.overview.time")}: {eventDetails.time}
                </Typography>
                <Typography
                  sx={{
                    marginTop: {
                      md: "10px",
                    },
                  }}
                >
                  {t("pages.overview.location")}: {eventDetails.location}
                </Typography>
              </>
            )}
          </Stack>
        </CollapsibleCheckoutOverviewSection>
      )}

      <Divider />

      <CollapsibleCheckoutOverviewSection
        title={t("pages.overview.tickets")}
        onEditClick={handleTicketDetailsEditClick}
        sx={{
          marginBottom: {
            md: 0,
            xs: isRedeemFlow ? "180px" : "220px",
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            color: theme.palette.grey[500],
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              width: isRedeemFlow ? "80%" : "40%",
              fontSize: "12px",
            }}
          >
            {t("pages.overview.ticketType")}
          </Typography>
          {!isRedeemFlow && (
            <Typography
              sx={{
                width: "20%",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {t("pages.overview.price")}
            </Typography>
          )}
          <Typography
            sx={{
              width: "20%",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {t("pages.tickets.quantity")}
          </Typography>
          {!isRedeemFlow && (
            <Typography
              sx={{
                width: "20%",
                fontSize: "12px",
                textAlign: "right",
              }}
            >
              {t("pages.overview.total")}
            </Typography>
          )}
        </Stack>
        <Stack direction="column" gap="15px">
          {[...ticketDetails.tickets, ...ticketDetails.extras].map((ticket, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems={isRedeemFlow ? "right" : "center"}
              sx={{
                color: "#444",

                "> *": {
                  lineHeight: "normal",
                },
              }}
            >
              <Typography width={isRedeemFlow ? "80%" : "40%"}>{ticket.name}</Typography>
              {!isRedeemFlow && (
                <Typography
                  width="20%"
                  align="center"
                  sx={{
                    wordWrap: "break-word",
                  }}
                >
                  {ticket.price}
                </Typography>
              )}
              <Typography width="20%" align="center" margin="auto 0">
                {ticket.quantity}
              </Typography>
              {!isRedeemFlow && (
                <Typography
                  width="20%"
                  textAlign="right"
                  sx={{
                    wordWrap: "break-word",
                  }}
                >
                  {ticket.total}
                </Typography>
              )}
            </Stack>
          ))}
        </Stack>
      </CollapsibleCheckoutOverviewSection>

      <Divider
        sx={{
          display: {
            md: "block",
            xs: "none",
          },
          marginBottom: "15px",
        }}
      />

      <BottomBar>
        <Stack
          sx={{
            margin: {
              md: "0",
              xs: "15px",
            },
          }}
        >
          <Box
            sx={{
              display: {
                md: "none",
                xs: "block",
              },
            }}
          >
            {terms}
          </Box>
          {!isRedeemFlow && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                marginTop: {
                  md: 0,
                  xs: "10px",
                },
              }}
            >
              <Typography>
                {t("pages.overview.fee", {
                  fee: APPLICATION_FEE_PERCENT,
                })}
                :
              </Typography>
              <Tooltip
                title={t("pages.overview.feeExplainer")}
                arrow
                disableFocusListener
              >
                <Box
                  component="img"
                  src={InfoIcon}
                  marginLeft="4px"
                  sx={{
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Typography marginLeft="auto">{ticketDetails.fee}</Typography>
            </Stack>
          )}
          {error && (
            <Typography marginTop={1} color="warning.main">
              {error.message}
            </Typography>
          )}
          <Stack
            direction="row"
            alignItems="baseline"
            sx={{
              marginTop: "10px",
              marginBottom: {
                md: "10px",
              },
            }}
          >
            <Typography fontSize="24px" fontWeight={700} marginRight="4px">
              {t("pages.overview.total")}
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              {t("pages.overview.includingVat")}:
            </Typography>
            <Typography
              fontSize="24px"
              fontWeight={700}
              marginLeft="auto"
              paddingLeft="4px"
            >
              {ticketDetails.total}
            </Typography>
          </Stack>
          <Stack
            direction={{
              md: "row",
              xs: "column",
            }}
          >
            <Box
              sx={{
                display: {
                  md: "block",
                  xs: "none",
                },
              }}
            >
              {terms}
            </Box>
            <Stack
              gap="10px"
              direction={{
                md: "column-reverse",
                xs: "column",
              }}
              sx={{
                marginLeft: {
                  md: "auto",
                },
              }}
            >
              <Stack direction="row" alignItems="center">
                <Box
                  component="img"
                  src={LockIcon}
                  margin="auto 2px auto 0"
                  width="14px"
                />
                <Typography color={theme.palette.grey[500]} fontSize="10px">
                  {t("pages.overview.safePaymentWithStripe")}
                </Typography>
              </Stack>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<img src={KeyboardArrowRightIcon} />}
                sx={{ width: { md: "300px" } }}
                disabled={!termsAccepted || paymentInProgress}
                onClick={handleGoToPaymentClick}
              >
                {bookingFlowType === BookingFlowType.VoucherRedeem
                  ? t("pages.overview.redeemVoucher")
                  : t("pages.overview.goToPayment")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </BottomBar>
    </Stack>
  );
};

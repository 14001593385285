import React from "react";
import { Box, Stack } from "@mui/system";
import { useTenantStore } from "../../store/tenant.store";
import { Typography } from "@mui/material";

export const TenantInfo = () => {
  const { tenantDetails } = useTenantStore();

  return (
    <Stack
      marginBottom={{
        md: "24px",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="column">
          <Typography fontSize={24} fontWeight={700} lineHeight="normal">
            {tenantDetails?.displayName}
          </Typography>
          <Typography
            display={{
              md: "block",
              xs: "none",
            }}
            fontSize={14}
            textAlign="left"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            width="800px"
            lineHeight="normal"
            title={tenantDetails?.shortDescription}
          >
            {tenantDetails?.shortDescription}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        fontSize={14}
        marginTop={0.5}
        textAlign="left"
        display={{
          md: "none",
          xs: "block",
        }}
      >
        {tenantDetails?.shortDescription}
      </Typography>
    </Stack>
  );
};

import { isSameDay } from "date-fns";
import { AvailableTimeslotsQuery, ServicesByTenantQuery } from "../graphql/graphql";
import { Timeslot } from "../components/ServiceSelector/ServiceSelector.types";
import { Ticket } from "../components/TicketSelector/TicketSelector.types";
import { UserStore } from "../store/user.store";

export type Service = ServicesByTenantQuery["servicesByTenant"][0];

export type TicketDataForDay = {
  booked: number;
  max: number;
  hasServiceWithoutMax: boolean;
};

export const defaultTicketDataForDay: TicketDataForDay = {
  booked: 0,
  max: 0,
  hasServiceWithoutMax: false,
};

export const getTicketRatios = (
  availableTimeslots: AvailableTimeslotsQuery["availableTimeslots"]
) => {
  const ticketsForDate: Record<number, TicketDataForDay> = {};

  availableTimeslots?.forEach((availableService) => {
    const service = availableService?.service;

    availableService.bookedTickets.forEach((timeslotTickets) => {
      const day = new Date(timeslotTickets.timeslot.start).getDate();
      if (!ticketsForDate[day]) {
        ticketsForDate[day] = defaultTicketDataForDay;
      }

      const timeslotBookedTickets = timeslotTickets.tickets.reduce(
        (acc, ticket) => acc + (ticket.bookedCount ?? 0),
        0
      );
      ticketsForDate[day] = {
        booked: ticketsForDate[day].booked + timeslotBookedTickets,
        max: ticketsForDate[day].max + (service.maxTicketCount ?? 0),
        hasServiceWithoutMax:
          ticketsForDate[day].hasServiceWithoutMax || !service.maxTicketCount,
      };
    });
  });

  return ticketsForDate;
};

const getTimeslotsForDate = (
  availableTimeslots: AvailableTimeslotsQuery["availableTimeslots"],
  date: Date,
  includeExtras = false
) => {
  return availableTimeslots?.reduce((acc, availableTimeslot) => {
    availableTimeslot.bookedTickets.forEach((availableTicket) => {
      const timeslot = new Date(availableTicket.timeslot.start);
      if (!isSameDay(timeslot, date)) {
        return;
      }

      availableTicket.tickets.forEach((ticket) =>
        acc.push({
          id: ticket.ticket.id,
          start: availableTicket.timeslot.start,
          end: availableTicket.timeslot.end,
          serviceId: availableTimeslot.service.id,
          bookedCount: ticket.bookedCount,
          maxCount: availableTimeslot.service.maxTicketCount,
        })
      );

      if (!includeExtras) return;

      availableTicket.extras.forEach((extra) =>
        acc.push({
          id: extra.extra.id,
          start: availableTicket.timeslot.start,
          end: availableTicket.timeslot.end,
          serviceId: availableTimeslot.service.id,
          isExtra: true,
        })
      );
    });

    return acc;
  }, [] as Timeslot[]);
};

export const getTimeslotsWithTicketsForDateByService = (
  availableTimeslots: AvailableTimeslotsQuery["availableTimeslots"],
  date: Date,
  includeExtras = false
): Record<string, Timeslot[]> => {
  const timeslotsForDayByService: Record<string, Timeslot[]> = {};

  const timeslotsForDate = getTimeslotsForDate(availableTimeslots, date, includeExtras);

  timeslotsForDate?.forEach((timeslot) => {
    const serviceId = timeslot.serviceId;

    if (!timeslotsForDayByService[serviceId]) {
      timeslotsForDayByService[serviceId] = [];
    }

    timeslotsForDayByService[serviceId].push(timeslot);
  });

  return timeslotsForDayByService;
};

export const getTicketsByTimeslots = (
  availableTimeslots: AvailableTimeslotsQuery["availableTimeslots"],
  selectedTimeslot: Timeslot | undefined,
  selectedEvent: { id: string; name: string } | undefined,
  selectedTickets: UserStore["selectedTickets"],
  services: Service[]
) => {
  const tickets: Ticket[] = [];
  const extras: Ticket[] = [];

  if (!availableTimeslots || !selectedTimeslot || !selectedEvent) {
    return {
      tickets,
      extras,
    };
  }

  const timeslotsByService = getTimeslotsWithTicketsForDateByService(
    availableTimeslots,
    new Date(selectedTimeslot.start),
    true
  );

  return timeslotsByService[selectedEvent.id]
    .filter((timeslot) => timeslot.start === selectedTimeslot.start)
    .reduce(
      (acc, timeslot) => {
        const service = services.find((service) => service.id === timeslot.serviceId);

        if (!timeslot.isExtra) {
          const result = service.ticketTypes.find(
            (ticketType) => ticketType.ticketType.id === timeslot.id
          );

          if (!result) return acc;
          const { ticketType } = result;

          acc.tickets.push({
            id: ticketType?.id,
            name: ticketType?.displayName ?? ticketType?.name,
            price: ticketType?.price,
            selectedQuantity: selectedTickets.tickets[ticketType?.id] || 0,
            maxQuantity: service.maxTicketCount ?? Infinity,
          } as Ticket);
        } else {
          const result = service.extras.find((extra) => {
            return extra.extra.id === timeslot.id;
          });

          if (!result) return acc;
          const { extra } = result;

          acc.extras.push({
            id: extra?.id,
            name: extra?.displayName ?? extra?.name,
            price: extra?.price,
            selectedQuantity: selectedTickets.extras[extra?.id] || 0,
            maxQuantity: Infinity,
          } as Ticket);
        }

        return acc;
      },
      { tickets, extras }
    );
};

export const getTickets = (
  selectedEvent: { id: string; name: string } | undefined,
  selectedTickets: UserStore["selectedTickets"],
  services: ServicesByTenantQuery["servicesByTenant"]
) => {
  const tickets: Ticket[] = [];
  const extras: Ticket[] = [];

  if (!selectedEvent) {
    return {
      tickets,
      extras,
    };
  }

  const service = services.find((service) => service.id === selectedEvent.id);
  if (!service) {
    return {
      tickets,
      extras,
    };
  }

  service.ticketTypes.forEach((ticketType) => {
    tickets.push({
      id: ticketType.ticketType.id,
      name: ticketType.ticketType?.displayName ?? ticketType.ticketType.name,
      price: ticketType.ticketType.price,
      selectedQuantity: selectedTickets.tickets[ticketType.ticketType.id] || 0,
      maxQuantity: service.maxTicketCount ?? Infinity,
    } as Ticket);
  });

  service.extras.forEach((extra) => {
    extras.push({
      id: extra.extra.id,
      name: extra.extra?.displayName ?? extra.extra.name,
      price: extra.extra.price,
      selectedQuantity: selectedTickets.extras[extra.extra.id] || 0,
      maxQuantity: Infinity,
    } as Ticket);
  });

  return {
    tickets,
    extras,
  };
};

export const groupTimeslotsByTime = (timeslots: Timeslot[]) => {
  const groupedTimeslots: Record<string, Timeslot[]> = {};

  timeslots.forEach((timeslot) => {
    if (!groupedTimeslots[timeslot.start]) {
      groupedTimeslots[timeslot.start] = [];
    }

    groupedTimeslots[timeslot.start].push(timeslot);
  });

  return groupedTimeslots;
};

const getTicketPrices = (service: Service) => {
  type TicketType = { id: string; price: number; name: string };

  return (service?.ticketTypes ?? []).reduce(
    (acc, ticketType) => {
      const type = ticketType.primary ? "primary" : "normal";

      acc[type].push({
        id: ticketType.id,
        price: ticketType.ticketType.price,
        name: ticketType.ticketType.displayName ?? ticketType.ticketType.name,
      });

      return acc;
    },
    { primary: [], normal: [] } as {
      primary: TicketType[];
      normal: TicketType[];
    }
  );
};

export const getLowestPrice = (service: Service) => {
  const { primary, normal } = getTicketPrices(service);
  const prices = primary.length ? primary : normal;

  let hasDifferentPrices = false;

  const lowestPrice = prices.reduce((acc, ticketType) => {
    if (ticketType.price !== acc) {
      hasDifferentPrices = true;
    }

    if (ticketType.price < acc) {
      return ticketType.price;
    }

    return acc;
  }, prices[0].price);

  return { lowestPrice, hasDifferentPrices };
};

export const getSortedTicketTypes = (service: Service) => {
  const { primary, normal } = getTicketPrices(service);
  const extras = (service?.extras ?? []).map((extra) => {
    return {
      id: extra.extra.id,
      name: extra.extra.name,
      price: extra.extra.price,
    };
  });

  return { tickets: [...primary, ...normal], extras };
};

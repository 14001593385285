export const formatCurrency = (amount: number, currency: string) => {
  if (!currency || amount == null) {
    return "";
  }

  const locale = navigator.languages?.length
    ? navigator.languages[0]
    : navigator.language;
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
  });

  const formatted = formatter.format(amount);
  // replace &nbsp; with normal space
  return formatted.replace("\u00A0", " ");
};

import React, { Fragment, useCallback } from "react";
import { Text as SlateText } from "slate";
import escapeHTML from "escape-html";
import { Typography } from "@mui/material";
import { TextNode } from "@payloadcms/richtext-slate";

export const RichText = ({ children }: { children: any[] }) => {
  const serialize = useCallback(
    (children) =>
      children.map((node, i) => {
        if (SlateText.isText(node)) {
          const textNode = node as TextNode;
          let text = <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />;

          if (textNode.bold) {
            text = <strong key={i}>{text}</strong>;
          }

          if (textNode.italic) {
            text = <em key={i}>{text}</em>;
          }

          if (textNode.underline) {
            text = <u key={i}>{text}</u>;
          }

          if (textNode.strikethrough) {
            text = <del key={i}>{text}</del>;
          }

          return <Fragment key={i}>{text}</Fragment>;
        }

        if (!node) {
          return null;
        }

        switch (node.type) {
          case "h1":
          case "h2":
          case "h3":
          case "h4":
          case "h5":
          case "h6":
            return (
              <Typography variant={node.type} key={i}>
                {serialize(node.children)}
              </Typography>
            );
          case "blockquote":
            return <blockquote key={i}>{serialize(node.children)}</blockquote>;
          case "ul":
          case "ol":
          case "li":
            return (
              <Typography component={node.type} key={i}>
                {serialize(node.children)}
              </Typography>
            );
          case "link":
            return (
              <a href={escapeHTML(node.url)} key={i}>
                {serialize(node.children)}
              </a>
            );

          default:
            return <Typography key={i}>{serialize(node.children)}</Typography>;
        }
      }),
    []
  );

  return <>{serialize(children)}</>;
};

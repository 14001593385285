import { Stack, useTheme } from "@mui/system";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { Chip } from "@mui/material";
import { TimeslotFromTo } from "./TimeslotFromTo/TimeslotFromTo";
import { defaultTheme } from "../../config/theme";
import { Fade } from "../Fade/Fade";
import { Timeslot } from "../ServiceSelector/ServiceSelector.types";
import testId from "../../../../cypress/support/testId";
import { useMemo } from "react";
import { groupTimeslotsByTime } from "../../util/service.util";
import { convertTZ } from "../../../models/globals/CompanyDetails/timezone";
import { useTenantStore } from "../../store/tenant.store";

type Props = {
  timeslots: Timeslot[];
  onSelect: (timeslot: Timeslot) => void;
};

export const TimeslotSelector = ({ timeslots: timeslotsByService, onSelect }: Props) => {
  const { t } = useFeTranslation();
  const { tenantDetails } = useTenantStore();
  const theme = useTheme(defaultTheme);

  const timeslots = useMemo(() => {
    const timeslotsByTime = groupTimeslotsByTime(timeslotsByService ?? []);

    const validTimeslots: Record<
      string,
      Timeslot & {
        fewTicketsLeft: boolean;
        order: number;
      }
    > = {};

    for (const [time, timeslots] of Object.entries(timeslotsByTime)) {
      const timeslot = timeslots[0];
      const fewTicketsLeft =
        timeslots[0].maxCount === Infinity
          ? false
          : (timeslot.maxCount - timeslot.bookedCount) / timeslot.maxCount <= 0.2;

      if (timeslot.maxCount === timeslot.bookedCount) {
        continue;
      }

      validTimeslots[time] = {
        ...timeslot,
        order: new Date(time).getTime(),
        fewTicketsLeft,
      };
    }

    return Object.values(validTimeslots).sort((a, b) => a.order - b.order);
  }, [timeslotsByService]);

  return (
    <Stack gap="15px" padding="15px 0">
      {timeslots?.map((timeslot, i) => (
        <Stack
          key={i}
          id={`ts-${i}`}
          direction="row"
          gap="8px"
          padding="15px"
          border={`1px solid ${theme.palette.secondary.main}`}
          borderRadius="2px"
          sx={{
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            ":hover": {
              // hover style subject to change - no design in Figma, just thought it looked nice
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            },
          }}
          onClick={() => onSelect(timeslot)}
          data-cy={testId.service.timeslot(i)}
        >
          <TimeslotFromTo
            time={convertTZ(timeslot.start, tenantDetails?.timezone)}
            variant="from"
          />
          <TimeslotFromTo
            time={convertTZ(timeslot.end, tenantDetails?.timezone)}
            variant="to"
          />
          {timeslot.fewTicketsLeft && (
            <Chip
              label={t("common.fewTicketsLeft")}
              size="small"
              variant="filled"
              color="warning"
              sx={{
                marginLeft: "auto",
                fontSize: "10px",
                padding: "2px 0",
                borderRadius: "2px",
                height: "unset",
              }}
            />
          )}
        </Stack>
      ))}
      {timeslots?.length > 0 && (
        <Fade id={`ts-${timeslots.length - 1}`} items={timeslots} />
      )}
    </Stack>
  );
};

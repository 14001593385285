import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import locales from "../locales";

const initI18n = () =>
  i18n.use(LanguageDetector).use(initReactI18next).init({
    nonExplicitSupportedLngs: true, // e.g. allow "en-US" to fallback to "en"
    fallbackLng: "en",
    resources: locales,
  });

export default initI18n;

import React from "react";
import "./index.css";
import initI18n from "./config/i18next";
import { createRoot } from "react-dom/client";
import { App } from "./components/App/App";
import { JitsuProvider } from "@jitsu/jitsu-react";

const root = createRoot(document.getElementById("home") as Element);

initI18n().then(() =>
  root.render(
    <JitsuProvider options={{ host: "https://data.flokk.io" }}>
      <App />
    </JitsuProvider>
  )
);

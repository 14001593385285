import React, { ReactNode, useEffect, useMemo } from "react";
import { StepIndicator } from "../../components/StepIndicator/StepIndicator";
import { Box, Stack, SxProps, Theme, useTheme } from "@mui/system";
import { Header } from "../../components/Header/Header";
import { TenantInfo } from "../../components/TenantInfo/TenantInfo";
import { SideTenantInfo } from "../../components/TenantInfo/SideTenantInfo";
import { useTenantStore } from "../../store/tenant.store";
import { defaultTheme } from "../../config/theme";
import { useStep } from "../../hooks/useStep";

type Props = {
  children: ReactNode;
  tenantInfoPosition?: "left" | "top" | "none";
  stepIndicatorVisible?: boolean;
  sx?: SxProps<Theme>;
  canThrow?: boolean;
  onBackButtonClick?: () => void;
};

export const MainLayout = ({
  tenantInfoPosition = "top",
  stepIndicatorVisible = true,
  children,
  sx,
  canThrow = true,
  onBackButtonClick,
}: Props) => {
  const { tenantError } = useTenantStore();
  const theme = useTheme(defaultTheme);

  useEffect(() => {
    if (tenantError && canThrow) {
      throw new Error(tenantError);
    }
  }, [tenantError, canThrow]);

  const handleBack = useMemo(
    // don't allow going back if we're on the error page
    () => (canThrow ? onBackButtonClick : null),
    [onBackButtonClick, canThrow]
  );

  return (
    <Box
      margin={{
        md: "0",
        xs: "0 15px",
      }}
      sx={sx}
    >
      <Header onBackButtonClick={handleBack} />
      <Stack
        direction={{
          md: "row",
        }}
        sx={{
          [theme.breakpoints.up("md")]: {
            width: "900px",
            borderRadius: "5px",
            margin: "110px auto",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.30)",
          },
          overflow: "hidden",

          "> *": {
            flex: 1,
          },
        }}
      >
        {tenantInfoPosition === "left" && <SideTenantInfo />}
        <Box
          sx={{
            backgroundColor: "#ffffff",
            padding: {
              md: "20px 20px 4px 20px",
            },
          }}
        >
          {tenantInfoPosition === "top" && <TenantInfo />}
          {stepIndicatorVisible && <StepIndicator />}
          {children}
        </Box>
      </Stack>
    </Box>
  );
};

export default {
  calendar: {
    day: (date: number) => `day-${date}`,
  },
  service: {
    card: {
      select: (id: string) => `service-card-${id}-select`,
      details: (id: string) => `service-card-${id}-details`,
    },
    timeslot: (index: number) => `timeslot-${index}`,
    details: {
      select: "service-details-select",
      container: "service-details-container",
    },
  },
  tickets: {
    increment: "tickets-increment",
    decrement: "tickets-decrement",
    next: "tickets-next",
  },
};

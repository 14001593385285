import { createStore, useStore } from "zustand";
import {
  AvailableTimeslotsQuery,
  ErrorReason,
  FallbackLocaleInputType,
  LocaleInputType,
  ServicesByTenantQuery,
  TenantDetailsQuery,
} from "../graphql/graphql";
import { sdk } from "../hooks/useSDK";
import { formatEndDate, formatStartDate } from "../util/date.util";
import { userStore } from "./user.store";

type TenantStore = {
  init: (tenantId: string, locale: string) => Promise<void>;
  tenantDetails: Exclude<TenantDetailsQuery["tenantDetails"], { __typename: "Error" }>;
  tenantError: ErrorReason | null;
  fetchTenantDetails: (tenantId: string) => Promise<void>;
  availableTimeslots: AvailableTimeslotsQuery["availableTimeslots"];
  fetchAvailableTimeslots: (
    tenantId: string,
    month: Date,
    serviceId?: string
  ) => Promise<void>;
  services: ServicesByTenantQuery["servicesByTenant"];
  fetchServicesByTenant: (tenantId: string) => Promise<void>;
};

export const tenantStore = createStore<TenantStore>((set, get) => ({
  init: async (tenantId) => {
    await get().fetchTenantDetails(tenantId);
    await get().fetchServicesByTenant(tenantId);
  },
  tenantDetails: null,
  tenantError: null,
  fetchTenantDetails: async (tenantId) => {
    const result = await sdk.tenantDetails({ input: tenantId });
    if (result.tenantDetails.__typename === "Error") {
      set({ tenantError: result.tenantDetails.reason });
    } else {
      set({ tenantDetails: result.tenantDetails });
    }
  },
  availableTimeslots: null,
  fetchAvailableTimeslots: async (tenantId, month, serviceId) => {
    set({ availableTimeslots: null });
    const result = await sdk.availableTimeslots({
      tenant: tenantId,
      startDate: formatStartDate(month),
      endDate: formatEndDate(month),
      serviceId,
    });
    set({ availableTimeslots: result.availableTimeslots });
  },
  services: [],
  fetchServicesByTenant: async (tenantId) => {
    const locale = userStore.getState().locale as LocaleInputType;
    const result = await sdk.servicesByTenant({
      input: tenantId,
      locale,
    });
    let services = result.servicesByTenant;
    set({ services });
  },
}));

export const useTenantStore = () => useStore(tenantStore);

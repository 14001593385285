import React from "react";
import { Box, Stack, lighten, useTheme } from "@mui/system";
import InfoIcon from "../../icons/InfoIcon.svg";
import { defaultTheme } from "../../config/theme";
import { Tooltip } from "@mui/material";

type Props = {
  icon: string;
  selected?: boolean;
  children: string;
  infoText: string;
  onClick?: () => void;
};

export const BookingFlowButton = ({
  icon,
  selected,
  children,
  onClick,
  infoText,
}: Props) => {
  const theme = useTheme(defaultTheme);

  return (
    <Stack
      sx={{
        padding: "10px",
        border: "1px solid",
        borderRadius: "2px",
        backgroundColor: selected ? theme.palette.primary.main : "#fff",
        color: selected ? "#fff" : "#000",
        borderColor: selected ? theme.palette.primary.main : "#000",
        gap: "10px",
        cursor: selected ? "default" : "pointer",

        img: {
          width: "24px",
        },

        ":hover": {
          backgroundColor: selected
            ? theme.palette.primary.main
            : lighten(theme.palette.primary.main, 0.9),
        },
      }}
      onClick={onClick}
    >
      <Box
        component="img"
        src={icon}
        sx={{
          // should probably use an actual svg loader at some point
          filter: selected ? "brightness(2)" : "brightness(0)",
        }}
      />
      <Stack direction="row" marginTop="auto">
        {children}
        <Tooltip title={infoText} arrow disableFocusListener>
          <Box
            component="img"
            src={InfoIcon}
            margin="auto 0 0 auto"
            sx={{
              filter: selected ? "brightness(2)" : "none",
            }}
          />
        </Tooltip>
      </Stack>
    </Stack>
  );
};

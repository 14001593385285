import React from "react";
import { Box, Stack } from "@mui/system";
import BackArrowIcon from "../../icons/BackArrowIcon.svg";
import { Button, Divider, Skeleton, Typography } from "@mui/material";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import CheckIcon from "../../icons/CheckOutlinedIcon.svg";
import testId from "../../../../cypress/support/testId";
import { RichText } from "../RichText/RichText";
import { Price } from "../Price/Price";
import { Service, getSortedTicketTypes } from "../../util/service.util";
import { useMemo } from "react";
import { useTenantStore } from "../../store/tenant.store";
import { BottomBar } from "../BottomBar/BottomBar";

type Props = {
  service: Service | null | undefined;
  onSelect: (service: Service) => void;
};

export const ServiceDetails = ({ service, onSelect }: Props) => {
  const { t } = useFeTranslation();

  const { tenantDetails } = useTenantStore();

  const ticketTypes = useMemo(
    () => (service ? getSortedTicketTypes(service) : []),
    [service]
  );

  if (!service) return null;

  return (
    <Stack data-cy={testId.service.details.container} paddingBottom={2}>
      <Stack
        marginBottom={{
          md: "0",
          xs: "80px",
        }}
      >
        {service && service?.image?.url && (
          <Box
            sx={{
              maxHeight: {
                md: "350px",
                xs: "185px",
              },
              marginBottom: {
                md: "20px",
                xs: "15px",
              },
            }}
          >
            <img
              src={service.image?.url}
              alt={service.image?.alt}
              style={{
                borderRadius: "10px",
                width: "100%",
                maxHeight: "inherit",
                objectFit: "cover",
              }}
            />
          </Box>
        )}
        {service ? (
          <Typography fontSize="24px" fontWeight="700" marginBottom="5px">
            {service.title}
          </Typography>
        ) : (
          <Skeleton
            variant="text"
            sx={{ fontSize: "24px", fontWeight: "700", marginBottom: "5px" }}
          />
        )}
        <Stack
          direction="row"
          alignItems="center"
          gap={{
            md: "12px",
            xs: "4px",
          }}
          marginBottom="5px"
        >
          <Typography fontWeight="700">{tenantDetails?.displayName}</Typography>
        </Stack>
        {service ? (
          <Typography marginBottom="20px">
            {t("serviceDetails.location")}: {service.location}
          </Typography>
        ) : (
          <Skeleton variant="text" sx={{ marginBottom: "20px" }} />
        )}

        <Stack
          direction="row"
          gap="1rem"
          flexWrap="wrap"
          sx={{
            "> *": {
              flex: "1 1 20%",
              maxWidth: "100%",
            },
          }}
        >
          {ticketTypes.tickets.map((ticketType) => (
            <Stack
              key={ticketType.id}
              direction={{
                md: "row",
                xs: "column",
              }}
              alignItems={{
                md: "center",
                xs: "flex-start",
              }}
              sx={{
                ".price": {
                  md: {
                    fontSize: "1rem",
                    lineHeight: "1.75",
                  },
                },

                "*": {
                  whiteSpace: "nowrap",
                },
              }}
            >
              <Typography
                sx={{
                  marginRight: {
                    md: "4px",
                    xs: "0",
                  },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  minWidth: "2rem",
                }}
              >
                {ticketType.name}:
              </Typography>
              <Price>{ticketType.price}</Price>
            </Stack>
          ))}
        </Stack>

        {service.longDescription?.length > 0 && (
          <>
            <Divider sx={{ margin: "20px 0" }} />
            <RichText>{service.longDescription}</RichText>
          </>
        )}

        {ticketTypes.extras.length > 0 && (
          <>
            <Divider sx={{ margin: "20px 0" }} />
            <Typography fontWeight="700" marginBottom="20px">
              {t("serviceDetails.extras")}
            </Typography>
            <Stack gap="20px">
              {service.extras.map(({ extra }) => (
                <Stack direction="row" gap="12px" key={extra.id}>
                  {(extra as any)?.image?.url && (
                    <Box
                      component="img"
                      src={(extra as any)?.image?.url}
                      width="20px"
                      height="20px"
                      marginTop="2px"
                    />
                  )}
                  <Stack direction="column">
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        ".price": {
                          fontSize: "1rem",
                          fontWeight: "normal",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          minWidth: "2rem",
                        }}
                      >
                        {extra.name}
                      </Typography>
                      <Typography margin="0 0.25rem">-</Typography>
                      <Price withUnit={false}>{extra.price}</Price>
                    </Stack>
                    {(extra as any).description?.length > 0 && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          lineHeight: "normal",
                        }}
                      >
                        {(extra as any).description}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </>
        )}
      </Stack>
      <BottomBar>
        {onSelect ? (
          <Button
            sx={{
              width: "360px",
              maxWidth: "100%",
              margin: {
                md: "20px auto",
                xs: "15px auto",
              },
            }}
            variant="contained"
            color="secondary"
            startIcon={<img src={CheckIcon} />}
            onClick={() => {
              onSelect(service);
            }}
            data-cy={testId.service.details.select}
          >
            {t("serviceDetails.select")}
          </Button>
        ) : null}
      </BottomBar>
    </Stack>
  );
};

export const ServiceDetailsSkeleton = () => {
  return (
    <Stack data-cy={testId.service.details.container} paddingBottom={2}>
      <Stack
        marginBottom={{
          md: "0",
          xs: "80px",
        }}
      >
        {service.image?.url && (
          <Box
            sx={{
              maxHeight: {
                md: "350px",
                xs: "185px",
              },
              marginBottom: {
                md: "20px",
                xs: "15px",
              },
            }}
          >
            <img
              src={service.image?.url}
              alt={service.image?.alt}
              style={{
                borderRadius: "10px",
                width: "100%",
                maxHeight: "inherit",
                objectFit: "cover",
              }}
            />
          </Box>
        )}
        <Typography fontSize="24px" fontWeight="700" marginBottom="5px">
          {service.title}
        </Typography>
      </Stack>
    </Stack>
  );
};

import React from "react";
import { Box, Stack, useTheme } from "@mui/system";
import { defaultTheme } from "../../../config/theme";
import { Divider, Typography } from "@mui/material";
import { useFeTranslation } from "../../../hooks/useFeTranslation";
import { Ticket } from "../TicketSelector.types";
import { formatCurrency } from "../../../util/i18n.util";
import { NumberInput } from "../../NumberInput/NumberInput";

type Props = {
  categoryName: string;
  tickets: Ticket[];
  currency: string;
  onChange: (id: string, value: number) => void;
};

export const TicketList = ({ categoryName, tickets, currency, onChange }: Props) => {
  const theme = useTheme(defaultTheme);
  const { t } = useFeTranslation();

  return (
    <Stack marginTop="10px">
      <Stack direction="row">
        <Typography
          sx={{
            width: {
              md: "60%",
              xs: "50%",
            },
            fontSize: "12px",
            color: theme.palette.grey[500],
          }}
        >
          {categoryName}
        </Typography>
        <Typography
          sx={{
            width: "20%",
            fontSize: "12px",
            textAlign: "center",
            color: theme.palette.grey[500],
          }}
        >
          {t("pages.tickets.price")}
        </Typography>
        <Typography
          sx={{
            width: {
              md: "20%",
              xs: "30%",
            },
            fontSize: "12px",
            textAlign: "right",
            color: theme.palette.grey[500],
          }}
        >
          {t("pages.tickets.quantity")}
        </Typography>
      </Stack>
      <Divider sx={{ margin: "10px 0" }} />
      <Stack>
        {tickets.map((ticket) => (
          <Stack
            key={ticket.id}
            direction="row"
            sx={{
              fontSize: "14px",
              lineHeight: "normal",
              margin: "5px 0",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                width: { md: "60%", xs: "50%" },
              }}
            >
              <Typography
                sx={{
                  lineHeight: "normal",
                }}
              >
                {ticket.name}
              </Typography>
              {ticket.description?.length && ticket.description?.length > 0 && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    lineHeight: "normal",
                  }}
                >
                  {ticket.description}
                </Typography>
              )}
            </Stack>
            <Typography
              sx={{
                width: "20%",
                textAlign: "center",
                lineHeight: "normal",
                wordWrap: "break-word",
              }}
            >
              {formatCurrency(ticket.price, currency)}
            </Typography>
            <Box
              sx={{
                marginLeft: "auto",
              }}
            >
              <NumberInput
                min={0}
                max={ticket.maxQuantity}
                value={ticket.selectedQuantity ?? 0}
                defaultValue={ticket.selectedQuantity ?? 0}
                onChange={(_, value) => onChange(ticket.id, value ?? 0)}
              />
            </Box>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

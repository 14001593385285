import { BookingExtras, BookingTickets } from "../../payload-types";

export const APPLICATION_FEE_PERCENT = 5;
export const PRICE_MULTIPLIER = 100;

export function applicationFeeFromBookable(
  tickets: BookingTickets,
  extras: BookingExtras
) {
  const totalCost =
    tickets.reduce((acc, t) => acc + t.price * t.count, 0) +
    (extras ?? []).reduce((acc, e) => acc + e.price * e.count, 0);

  return Math.floor((APPLICATION_FEE_PERCENT / 100) * totalCost * 100);
}

export function bookableTotalPrice(tickets: BookingTickets, extras: BookingExtras) {
  return (
    ((tickets.map((t) => t.price * t.count).reduce((acc, p) => acc + p, 0) +
      extras.map((e) => e.price * e.count).reduce((acc, p) => acc + p, 0)) *
      (1 + APPLICATION_FEE_PERCENT / 100)) /
    PRICE_MULTIPLIER
  );
}

import { Stack } from "@mui/system";
import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import UsingBinoculars from "../../icons/UsingBinoculars.svg";
import { Button, Typography } from "@mui/material";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { useStep } from "../../hooks/useStep";
import { ErrorReason } from "../../graphql/graphql";
import { useMemo } from "react";

type Props = {
  reason: ErrorReason | string;
};

export const ErrorPage = ({ reason: errorReason }: Props) => {
  const { t } = useFeTranslation();
  const { goTo } = useStep();

  const reason = useMemo(
    () =>
      errorReason === ErrorReason.NotFound || errorReason === ErrorReason.ApplicationError
        ? errorReason
        : ErrorReason.ApplicationError,
    [errorReason]
  );

  const handleButtonClick = () => {
    switch (reason) {
      case ErrorReason.NotFound:
      case ErrorReason.ApplicationError:
      default:
        goTo(`/`);
        break;
    }
  };

  return (
    <MainLayout stepIndicatorVisible={false} tenantInfoPosition="none" canThrow={false}>
      <Stack alignItems="center" margin="80px 0">
        <img src={UsingBinoculars} />
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 700,
            lineHeight: "normal",
          }}
        >
          {t(`pages.${reason}.title`)}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            lineHeight: "normal",
            textAlign: "center",
          }}
        >
          {t(`pages.${reason}.description`)}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleButtonClick}
          sx={{ marginTop: "30px", padding: "8px 80px" }}
        >
          {t(`pages.${reason}.button`)}
        </Button>
      </Stack>
    </MainLayout>
  );
};

import { createTheme } from "@mui/material/styles";
import { borderColor, borderRadius } from "@mui/system";

export const defaultTheme = createTheme({
  typography: {
    fontFamily: ['"Space Grotesk"', "sans-serif"].join(","),
  },
  palette: {
    background: {
      default: "#F9F4F0",
    },
    primary: {
      main: "#0EA88D",
    },
    warning: {
      main: "#ECD326",
    },
    secondary: {
      main: "#000000",
    },
    grey: {
      "50": "#ECE8E4",
      "100": "#DFDCD8",
      "200": "#C5C4C0",
      "300": "#ABACA8",
      "400": "#919490",
      "500": "#777C78",
      "600": "#5D6460",
      "700": "#434C48",
      "800": "#293430",
      "900": "rgb(20, 20, 20)",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "2px",
          fontSize: "18px",
          padding: "2px 16px",
          textTransform: "none",
          fontWeight: "normal",

          ":disabled": {
            color: "#ffffff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        outlined: {
          padding: "1px 16px",
        },
        startIcon: {
          marginRight: "4px",
          marginLeft: "-2px",
        },
        endIcon: {
          marginLeft: "4px",
          marginRight: "-2px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#000000",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: "12px",
          padding: "0 2px",
        },
        colorWarning: {
          color: "#444444",
        },
        colorSecondary: {
          backgroundColor: "#ffffff",
        },
        // @ts-ignore-next-line - this works, the types are not correct for some reason
        colorDefault: {
          backgroundColor: "#C6C6C6",
          color: "#ffffff",
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          padding: "15px 0",
          "&::before": {
            display: "none",
          },

          "& .MuiAccordionSummary-root": {
            padding: 0,
            flexDirection: "row-reverse",
            minHeight: "auto",
          },
          "& .MuiAccordionSummary-content": {
            fontWeight: 700,
            lineHeight: "normal",
            fontSize: "16px",
            margin: 0,
          },
          "& .Mui-expanded": {
            margin: 0,
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: "4px",
          },
          "& .MuiAccordionDetails-root": {
            padding: "5px 0 0 0",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#000000",
          textDecorationColor: "#000000",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "2px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiFormHelperText-root": {
            marginLeft: 0,
          },
        },
      },
    },
  },
});

import { Stack } from "@mui/system";
import BackArrowIcon from "../../icons/BackArrowIcon.svg";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useFeTranslation } from "../../hooks/useFeTranslation";

type Props = {
  onBackButtonClick?: () => void;
};

export const Header = ({ onBackButtonClick }: Props) => {
  const { t } = useFeTranslation();

  return (
    <Stack
      direction="row"
      sx={{
        padding: {
          md: "15px 80px",
          xs: "15px 0 5px 0",
        },
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#fff",
        boxShadow: {
          md: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{
          height: "30px",
          cursor: "pointer",
        }}
        onClick={onBackButtonClick}
      >
        {onBackButtonClick && (
          <>
            <img src={BackArrowIcon} />
            {t("common.back")}
          </>
        )}
      </Stack>
      <LanguageSelector />
    </Stack>
  );
};

import { CompanyDetail } from "../../../payload-types";
import timezones from "../../../admin/assets/eu-timezones.json";

export const TIMEZONE_MAPPING: Record<CompanyDetail["address"]["country"], string[]> =
  timezones;

export function convertTZ(date: Date | string, timezone: string) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: timezone ?? "Europe/Budapest",
    })
  );
}

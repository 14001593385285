import { useLocation } from "wouter";
import { Stack } from "@mui/material";
import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { CalendarLabels } from "../../components/CalendarLabels/CalendarLabels";
import { useUserStore } from "../../store/user.store";
import { useTenantStore } from "../../store/tenant.store";
import { DatePickerCalendar } from "../../components/DatePickerCalendar/DatePickerCalendar";
import { useStep } from "../../hooks/useStep";
import { BookingFlowSelector } from "../../components/BookingFlowSelector/BookingFlowSelector";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";
import { useEffect } from "react";

export default function DatePage() {
  const [, setLocation] = useLocation();
  const { t } = useFeTranslation();
  const { goForward } = useStep();

  const {
    selectedEvent,
    selectedDate,
    setSelectedDate,
    bookingFlowType,
    month,
    setMonth,
    setBookingFlowType,
    voucherCode,
    reset,
  } = useUserStore();
  const { availableTimeslots, services } = useTenantStore();
  const hasVoucherPurchaseService = services.filter((s) => s.vouchersEnabled).length > 0;

  useEffect(() => {
    if (bookingFlowType !== BookingFlowType.FullBooking || !!voucherCode?.length) return;

    setBookingFlowType(BookingFlowType.FullBooking);
    reset();
  }, [voucherCode]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    goForward();
  };

  return (
    <MainLayout
      tenantInfoPosition="left"
      onBackButtonClick={
        bookingFlowType === BookingFlowType.ServicePreselect
          ? () => {
              setLocation(`/bookservice/${selectedEvent?.id}`);
            }
          : null
      }
    >
      {hasVoucherPurchaseService ? <BookingFlowSelector /> : null}
      <PageTitle title={t("pages.date.title")} helpText={t("pages.date.helpText")} />
      <Stack direction="column" alignContent="center">
        <DatePickerCalendar
          selectedDate={selectedDate}
          onChange={handleDateChange}
          month={month}
          setMonth={setMonth}
          availableTimeslots={availableTimeslots}
        />
        <CalendarLabels />
      </Stack>
    </MainLayout>
  );
}

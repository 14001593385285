import { Box, Stack, useTheme } from "@mui/system";
import { useTenantStore } from "../../store/tenant.store";
import { Typography, useMediaQuery } from "@mui/material";
import { defaultTheme } from "../../config/theme";
import Logo from "../../icons/Logo";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { TenantInfo } from "./TenantInfo";

// if you have a better name for this component, I'm open to suggestions
export const SideTenantInfo = () => {
  const { tenantDetails } = useTenantStore();
  const theme = useTheme(defaultTheme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useFeTranslation();

  if (tenantDetails?.__typename === "Error") {
    return null;
  }

  if (isMobile) {
    return <TenantInfo />;
  }

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        padding: "20px",
      }}
      justifyContent="space-evenly"
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        {tenantDetails?.logoURL && (
          <img src={tenantDetails.logoURL} alt="logo" height="80px" max-width="100%" />
        )}
        <Typography fontSize={24} fontWeight={700}>
          {tenantDetails?.displayName}
        </Typography>
        <Typography fontSize={14} marginTop="1.5rem !important" textAlign="center">
          {tenantDetails?.shortDescription}
        </Typography>
      </Stack>
      <Stack
        display="flex"
        direction="row"
        justifyContent="center"
        textAlign="center"
        gap="8px"
      >
        {t("common.poweredBy")}
        <Logo fill="white" width="24px" height="24px" />
        Flokk
      </Stack>
    </Stack>
  );
};

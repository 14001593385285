import React from "react";
import { Box, Stack } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { useFeTranslation } from "../../../hooks/useFeTranslation";
import EyeIcon from "../../../icons/EyeIcon.svg";
import CheckIcon from "../../../icons/CheckOutlinedIcon.svg";
import testId from "../../../../../cypress/support/testId";
import { Price } from "../../Price/Price";
import { Service, getLowestPrice } from "../../../util/service.util";
import { useMemo } from "react";
import { useTenantStore } from "../../../store/tenant.store";

type Props = {
  service: Service;
  onSelect?: (service: Service) => void;
  onDetails: (service: Service) => void;
};

export const ServiceCard = ({ service, onSelect, onDetails }: Props) => {
  const { t } = useFeTranslation();

  const { lowestPrice: price, hasDifferentPrices } = useMemo(
    () => getLowestPrice(service),
    [service]
  );

  return (
    <Stack direction="row" width="100%" margin="16px 0" id={`sc-${service.id}`}>
      {service.image?.url && (
        <Box
          sx={{
            minWidth: {
              md: "250px",
              xs: "120px",
            },
            width: {
              md: "250px",
              xs: "120px",
            },
            minHeight: {
              md: "185px",
              xs: "180px",
            },
            height: {
              md: "185px",
              xs: "180px",
            },
            marginRight: "10px",
          }}
        >
          <img
            src={service.image.sizes?.thumbnail?.url ?? service.image?.url}
            alt={service.image?.alt}
            style={{
              width: "inherit",
              height: "inherit",
              objectFit: "cover",
              borderRadius: "2px",
            }}
          />
        </Box>
      )}
      <Stack
        sx={{
          flex: "1",
          maxWidth: service.image?.url ? "calc(100% - 120px - 10px)" : "100%", // 100% - imageWidth - margin
        }}
      >
        <Typography fontWeight="700" noWrap>
          {service.title}
        </Typography>
        <Typography
          fontSize="14px"
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {service.location}
        </Typography>
        <Stack
          marginTop="auto"
          direction={{
            md: service.image?.url?.length ? "column" : "row",
            xs: "column",
          }}
        >
          <Price withFrom={hasDifferentPrices}>{price}</Price>
          <Stack
            gap={{
              md: "14px",
              xs: "4px",
            }}
            direction={{
              md: "row",
              xs: "column",
            }}
            marginTop={{
              md: service.image?.url?.length ? "4px" : "24px",
              xs: "4px",
            }}
            marginLeft={{
              md: service.image?.url?.length ? "0" : "auto",
            }}
            width={{
              md: service.image?.url?.length ? "auto" : "415px",
            }}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<img src={EyeIcon} />}
              onClick={() => onDetails(service)}
              sx={{
                flex: "50%",
              }}
              data-cy={testId.service.card.details(service.id)}
            >
              {t("pages.event.details")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<img src={CheckIcon} />}
              onClick={() => onSelect(service)}
              sx={{
                flex: "50%",
              }}
              data-cy={testId.service.card.select(service.id)}
            >
              {t("pages.event.select")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

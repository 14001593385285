import React, { useEffect } from "react";
import { useTenantStore } from "../../store/tenant.store";
import { useJitsu } from "@jitsu/jitsu-react";
import { ThemeProvider } from "@mui/system";
import { defaultTheme } from "../../config/theme";
import { ServicePage } from "../../routes/ServicePage";
import { TicketPage } from "../../routes/TicketPage";
import { OverviewPage } from "../../routes/OverviewPage";
import { Route, Switch, useLocation, useRoute } from "wouter";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePage from "../../routes/DatePage";
import { TimeslotPage } from "../../routes/TimeslotPage";
import { Step, useStep } from "../../hooks/useStep";
import * as Sentry from "@sentry/react";
import {
  ServiceDetailsPage,
  ServicePreselectPage,
} from "../../routes/ServiceDetailsPage";
import { ErrorPage } from "../../routes/ErrorPage";
import { ErrorReason } from "../../graphql/graphql";
import { PaymentSuccess } from "../../routes/PaymentSuccessPage/PaymentSuccessPage";
import { useUserStore } from "../../store/user.store";
import { BookingFlowType } from "../../../models/constants/BookingFlowType";
import { useTenant } from "../../hooks/useTenant";
import CookieBanner from "../CookieBanner";
import GoogleAnalytics from "../GoogleAnalytics";
import { ConsentCategory, useConsentStore } from "../../store/consent.store";

export const App = () => {
  const tenant = useTenant();
  const [, params] = useRoute<{ step?: string; serviceid?: string }>(
    "/:step?/:serviceid?"
  );
  const { goToStep, currentStep } = useStep();
  const [location, _] = useLocation();
  const { analytics } = useJitsu();
  const { init, fetchAvailableTimeslots, services, fetchServicesByTenant } =
    useTenantStore();
  const { locale } = useUserStore();
  const { month, selectedEvent, bookingFlowType, voucherCode, fetchVoucherDetails } =
    useUserStore();
  const { acceptedCategories } = useConsentStore();

  useEffect(() => {
    if (acceptedCategories.includes(ConsentCategory.Analytics)) {
      analytics.page({ bookingFlowType, currentStep, tenant, pageType: "booking" });
    }
  }, [location, acceptedCategories]);

  useEffect(() => {
    if (params?.step === "booking-success") return;
    if (!tenant) return;

    if (!params?.step) {
      goToStep(Step.Date);
    }
  }, []);

  useEffect(() => {
    init(tenant, locale);
  }, [locale]);

  useEffect(() => {
    if (!bookingFlowType || params?.step === "booking-success") return;

    if (
      bookingFlowType === BookingFlowType.ServicePreselect ||
      bookingFlowType === BookingFlowType.VoucherRedeem
    ) {
      fetchAvailableTimeslots(tenant, month as Date, selectedEvent?.id);
    } else if (bookingFlowType === BookingFlowType.FullBooking) {
      fetchAvailableTimeslots(tenant, month as Date);
    }
    if (!services) {
      fetchServicesByTenant(tenant);
    }
  }, [month, bookingFlowType, selectedEvent?.id, tenant, locale]);

  useEffect(() => {
    if (!voucherCode) return;

    fetchVoucherDetails();
  }, [voucherCode]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Sentry.ErrorBoundary
          fallback={(err) => <ErrorPage reason={err.error.message} />}
        >
          <Switch>
            <Route path="/booking-success" component={PaymentSuccess} />
            <Route path="/" component={DatePage} />
            <Route path="/date" component={DatePage} />
            <Route path="/service" component={ServicePage} />
            <Route path="/timeslot" component={TimeslotPage} />
            <Route path="/tickets" component={TicketPage} />
            <Route path="/overview" component={OverviewPage} />
            <Route path="/service/:serviceid" component={ServiceDetailsPage} />
            <Route path="/bookservice/:serviceid" component={ServicePreselectPage} />
            <Route>
              <ErrorPage reason={ErrorReason.NotFound} />
            </Route>
          </Switch>
          <CookieBanner />
          <GoogleAnalytics />
        </Sentry.ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

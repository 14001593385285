import { Box } from "@mui/system";
import { ServiceCard } from "./ServiceCard/ServiceCard";
import { Fragment } from "react";
import { Divider } from "@mui/material";
import { Fade } from "../Fade/Fade";
import { Service } from "../../util/service.util";

type Props = {
  services: Service[];
  onSelect: (service: Service) => void;
  onDetails: (service: Service) => void;
};

export const ServiceSelector = ({ services, onSelect, onDetails }: Props) => {
  return (
    <Box>
      {services.map((service, i) => (
        <Fragment key={service.id}>
          <ServiceCard service={service} onSelect={onSelect} onDetails={onDetails} />
          {i !== services.length - 1 && <Divider />}
        </Fragment>
      ))}
      {services?.length > 0 && (
        <Fade id={`sc-${services[services.length - 1].id}`} items={services} />
      )}
    </Box>
  );
};
